import React, { useState } from "react";
import userHelper from "../../helper/user";
import { useForm } from "react-hook-form";
import GlobalButton, { BUTTON_TYPES } from "../globalButton/GlobalButton";

function ChangePasswordForm(props) {
  const [response, setResponse] = useState("");
  const [alert, setAlert] = useState(""); // => condiziona il form e il login button!!
  const { searchParams } = new URL(window.location.href);
  const [loading, setLoading] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);

  const password_regex = new RegExp(process.env.REACT_APP_REGEX_PASSWORD);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
  });

  const messages = {
    success: {
      level: "success",
      label: "Registrazione avvenuta con successo.",
      message: "Registrazione avvenuta con successo.",
    },

    denied: {
      level: "danger",
      label: "Registrazione non riuscita.",
      message: "Registrazione non riuscita.",
    },

    generic_message: {
      level: "warning",
      label: "Problemi registrazione.",
      message: "Riprova più tardi.",
    },
  };

  const responseMessage = {
    200: messages.success,
    201: messages.success,
    403: messages.denied,
    generic_message: messages.generic_message,
    default: messages.generic_message,
  };

  const handleInputChange = async (data) => {
    setLoading(true);
    props.setAlertStatus(false);

    try {
      const status = await userHelper.changePassword(
        searchParams.get("email"),
        searchParams.get("token"),
        data.password,
        data.confirmedPassword
      );
      setResponse(status.data);
      setAlert("success");
      setAlertStatus(true);
      console.log(status);
    } catch (error) {
      console.log(error);
      const generalErrors = error.response.data;
      const { response } = error;
      const { status } = !!response.status ? response : "generic_message";
      const { level, label, message } = !!responseMessage[status]
        ? responseMessage[status]
        : responseMessage["generic_message"];
      console.log(generalErrors);
      generalErrors.map((generalError) => {
        props.setAlertMessage({
          level: generalError.level,
          label: generalError.message,
          message: generalError.message,
        });
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <p className="login-heading mb-4">Cambia la tua password:</p>
      {!!loading ? (
        <div className="spinner-border" role="status" />
      ) : (
        <>
          {!!alert && alertStatus && (
            <>
              {" "}
              <div className={`alert alert-${alert} alert-dismissible fade show`} role="alert">
                <strong>{response}</strong>
                <br></br>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setAlertStatus(false)}></button>
              </div>
            </>
          )}
          {alert !== "success" ? (
            <form id="loginForm" onSubmit={handleSubmit(handleInputChange)}>
              <div className="form-floating mb-3">
                <input
                  name="password"
                  type="password"
                  {...register("password", {
                    required: true,
                    pattern: password_regex,
                  })}
                  className={`form-control ${!errors?.password ? " " : " is-invalid "}`}
                  id="password"
                  placeholder="Password"
                  required
                />
                <label htmlFor="floatingPassword">Password</label>
                {errors.password && (
                  <>
                    <p className="ps-2 text-danger">
                    La nuova password deve soddisfare i seguenti requisiti:
                    </p>
                    <ul className="text-danger ps-4">
                      <li>Contenere almeno una lettera minuscola.</li>
                      <li>Contenere almeno una lettera maiuscola.</li>
                      <li>Includere almeno un carattere speciale tra i seguenti: <code>!@#$%-</code></li>
                      <li>Avere una lunghezza compresa tra 8 e 24 caratteri.</li>
                    </ul>
                  </>
                )}
              </div>
              <div className="form-floating mb-3">
                <input
                  name="confirmedPassword"
                  type="password"
                  {...register("confirmedPassword", {
                    validate: (value) => {
                      const { password } = getValues();
                      return password === value;
                    },
                  })}
                  className={`form-control ${!errors?.confirmedPassword ? "  " : " is-invalid "}`}
                  id="confirmedPassword"
                  placeholder="Conferma Password"
                  required
                />
                <label htmlFor="floatingPassword">Conferma Password</label>
                {errors.confirmedPassword && <p className="ps-2 text-danger">Le password non coincidono.</p>}
              </div>
              <GlobalButton disabled={!isDirty || !isValid}> Cambia Password </GlobalButton>
            </form>
          ) : (
            <GlobalButton buttonType={BUTTON_TYPES.a}> Vai al login </GlobalButton>
          )}
        </>
      )}
    </>
  );
}

export default ChangePasswordForm;
