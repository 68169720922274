import axios from "axios";

import authHelper from "./auth";

const me = async () => {
  const url = `/api/v1/user/me`;
  const auth = authHelper.get();

  axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${auth.jwt}`;

  let user = null;
  try {
    const { data } = await axios.get(url);
    user = data;
  } catch (error) {
    authHelper.remove();
    throw error;
  }

  return user;
};

const logout = () => {
  authHelper.remove();
};

// const isLogged = () => {
//   const user = me();
//   return !!user;
// };

const isLogged = async () => {
  try {
    const user = await me(); 
    return !!user; 
  } catch (error) {
    return false; 
  }
};

const registerUser = async (email, password, firstName, lastName, IDMatricola) => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

  const url = `/api/v1/user`;
  const datetime = new Date().toLocaleString();
  const response = await axios.post(url, {
    name: firstName,
    last_name: lastName,
    id_matricola: IDMatricola,
    email: email,
    password: password,
    datetime,
  });

  return response;
};

const confirmEmail = async (email, token) => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
  const url = `/api/v1/user/confirm`;

  const response = await axios.post(url, {
    email: email,
    token: token,
  });

  return response;
};

const forgotPassword = async (email) => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

  const url = `/api/v1/forgot-password`;
  const datetime = new Date().toLocaleString();

  const response = await axios.post(url, {
    email: email,
    datetime,
  });
  return response;
};

const changePassword = async (email, token, password, confirmPassword) => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

  const url = `/api/v1/reset-password`;

  const response = await axios.post(url, {
    email: email,
    password: password,
    token: token,
  });
  return response;
};

const reSendEmail = async (email) => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

  const url = `/api/v1/user/resend-email`;

  const response = await axios.post(url, {
    email: email,
  });
  return response;
};

const userHelper = {
  me,
  logout,
  isLogged,
  forgotPassword,
  changePassword,
  confirmEmail,
  reSendEmail,
  registerUser,
};

export default userHelper;
