import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import authHelper from "../../helper/auth";
import ResendEmail from "../ResendEmail/ResendEmail";
import GlobalButton from "../globalButton/GlobalButton";

function LoginForm(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [formResendEmail, setFormResendEmail] = useState(false);
  const history = useHistory();

  const messages = {
    success: {
      level: "success",
      label: "Successo",
      message: "Accesso riuscito, ora verrai reindirizzato.",
    },

    denied: {
      level: "danger",
      label: "Accesso non riuscito.",
      message: "Credenziali non riconosciute.",
    },

    unverifiedemailException: {
      level: "warning",
      label: "Utente in attesa di validazione",
      message: "Convalidare email.",
    },

    generic_message: {
      level: "warning",
      label: "Problemi autenticazione.",
      message: "Riprova più tardi.",
    },
  };

  const responseMessage = {
    200: messages.success,
    201: messages.success,
    403: messages.denied,
    unverifiedemailException: messages.unverifiedemailException,
    generic_message: messages.generic_message,
    default: messages.generic_message,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    props.setAlertStatus(false);
    setFormResendEmail(false);
    try {
      const { status } = await makeRequest(username, password);
      notificationResponseHandler(status);
      const { searchParams } = new URL(window.location.href);
      const queryString = searchParams.toString();
      const url = `/redirect${!!queryString ? `?${queryString}` : ""}`;
      history.push(url);
    } catch (error) {
      const { response } = error;
      const { error: exception } = response.data;
      let { status } = !!response.status ? response : "generic_message";
      switch (exception) {
        case "UnverifiedemailException":
          status = "unverifiedemailException";
          setFormResendEmail(true);
          break;
      }
      const { level, label, message } = !!responseMessage[status]
        ? responseMessage[status]
        : responseMessage["generic_message"];
      props.setAlertMessage({
        level,
        label,
        message,
      });
    } finally {
      setLoading(false);
    }
  };

  const makeRequest = (email, password) => {
    return authHelper.login(email, password);
  };

  const notificationResponseHandler = (res) => {
    const responseCode = !!res ? res : "default";

    if (!!responseMessage[responseCode]) {
      const { level, label, message } = responseMessage[responseCode];
      props.setAlertMessage({
        level,
        label,
        message,
      });
    }
  };

  return (
    <>
      {!formResendEmail ? (
        <>
          <p className="login-heading mb-4">Accedi qui sotto:</p>
          {!!loading ? (
            <div className="spinner-border" role="status" />
          ) : (
            <form id="loginForm" onSubmit={handleSubmit}>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control"
                  id="username"
                  placeholder="name@example.com"
                  required
                />
                <label htmlFor="floatingInput">Email o ID Matricola</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  required
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              <div className="form-check mb-3 d-none">
                <input className="form-check-input" type="checkbox" value="" id="rememberPasswordCheck" />
                <label className="form-check-label" htmlFor="rememberPasswordCheck">
                  Ricordami
                </label>
              </div>
              <GlobalButton> Login </GlobalButton>
              <div className="pt-4 pb-2">
                <a href="/forgot-password" className="non-account text-decoration-none">
                  Hai dimenticato la password?
                </a>
              </div>
              <div className="pt-2">
                Sei un dipendente?{" "}
                <a href="/registration" className="non-account text-decoration-none">
                  Clicca qui per registrarti
                </a>.
              </div>
            </form>
          )}
        </>
      ) : (
        <ResendEmail email={username} {...props} />
      )}
    </>
  );
}

export default LoginForm;
