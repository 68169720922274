import { useState } from "react";
import userHelper from "../../helper/user";
import { useForm } from "react-hook-form";
import ResendEmail from "../ResendEmail/ResendEmail";
import uuid from "react-uuid";

function RegistrationFormWithLibrary(props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [exitForm, setExitForm] = useState(false);
  const [messageError, setMessageError] = useState();
  //const [goToRe, setGoToRe] = useState(false);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
  });

  const messages = {
    success: {
      level: "success",
      label: "Registrazione avvenuta con successo.",
      message: "Registrazione avvenuta con successo.",
    },

    denied: {
      level: "danger",
      label: "Registrazione non riuscita.",
      message: "Registrazione non riuscita.",
    },

    generic_message: {
      level: "warning",
      label: "Problemi registrazione.",
      message: "Riprova più tardi.",
    },
  };

  const responseMessage = {
    200: messages.success,
    201: messages.success,
    403: messages.denied,
    generic_message: messages.generic_message,
    default: messages.generic_message,
  };
  const notificationResponseHandler = (res) => {
    const responseCode = !!res ? res : "default";

    if (!!responseMessage[responseCode]) {
      const { level, label, message } = responseMessage[responseCode];
      props.setAlertMessage({
        level,
        label,
        message,
      });
    }
  };

  // function extractDomainFromEmail(email) {

  //   const domainString = process.env.REACT_APP_ALLOWED_DOMAINS;
  //   // const domainString = "gmail.com,ares.com";
  //   const domainArray = domainString.split(',');
  
  //   const trimmedDomainArray = domainArray?.map(domain => domain?.trim());
  
  //   const emailParts = email.split('@');
  
  //   const domainEmailRequest = emailParts.pop();
  
  //   if (trimmedDomainArray.includes(domainEmailRequest)) {
  //     return domainEmailRequest;
  //   } else {
  //     return null; 
  //   }
  // }

  const handleInputChange = async (data) => {

    // e.preventDefault();
    setLoading(true);
    props.setAlertStatus(false);
    //setExitForm(false);

    try {

      const response = await userHelper.registerUser(
        data.email,
        data.password,
        data.firstName,
        data.lastName,
        data.id_matricola,
      );
      setEmail(data?.email);
      notificationResponseHandler(response?.status);
      //setGoToRe(true);
      setExitForm(true);

    } catch (error) {
      
      const generalErrors = error.response.data;
      setMessageError(generalErrors);
      const { response } = error;
      const { status } = !!response.status ? response : "generic_message";
      const { level, label, message } = !!responseMessage[status]
        ? responseMessage[status]
        : responseMessage["generic_message"];
      props.setAlertMessage({
        level,
        label,
        message,
      });
      setExitForm(false);
    } finally {
      setLoading(false);
    }
  };

  return (

    <>
      {!!loading ? (
        <div className="spinner-border" role="status" />
      ) : (
        <>
          {!exitForm ? (
            <>
              <p className="login-heading mb-4">Registrazione:</p>
              <form id="loginForm" onSubmit={handleSubmit(handleInputChange)}>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    {...register("firstName", { required: true, maxLength: 20 })}
                    className={`form-control ${!errors?.firstName ? " " : " is-invalid "}`}
                    id="firstName"
                    placeholder="Nome"
                    required
                  />
                  <label htmlFor="floatingInput">Nome </label>
                  {errors?.firstName && <p className="ps-2 text-danger">Formato nome non valido</p>}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    {...register("lastName", { required: true, maxLength: 20 })}
                    id="lastName"
                    className={`form-control ${!errors?.lastName ? " " : " is-invalid "}`}
                    placeholder="Cognome"
                    required
                  />
                  <label htmlFor="floatingInput">Cognome</label>
                  {errors.lastName && <p className="ps-2 text-danger">Formato nome non valido</p>}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    {...register("email", {
                      required: true,
                      pattern: new RegExp(process.env.REACT_APP_REGEX_EMAIL)
                      // validate: (value) => {
                      //   const domain = extractDomainFromEmail(value);
                      //   return domain !== null || 'Email con formato non valido. Assicurati di includere anche il dominio corretto';
                       //},
                    })}
                    className={`form-control ${errors?.email || messageError?.email ? " is-invalid " : "  "}`}
                    id="email"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="floatingInput">Email</label>
                  {messageError?.email?.map(({ message }) => (
                    <p key={uuid()} className="ps-1 text-danger">
                      {message}
                    </p>
                  ))}
                  {errors?.email && <p className="ps-2 text-danger">Email con formato non valido. Assicurati di includere anche il dominio corretto</p>}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="id_matricola"
                    {...register("id_matricola", {
                      required: false
                    })}
                    className={`form-control ${errors?.id_matricola || messageError?.id_matricola ? " is-invalid " : "  "}`}
                    id="id_matricola"
                  />
                  <label htmlFor="floatingInput">ID Matricola</label>
                  {messageError?.id_matricola?.map(({ message }) => (
                    <p key={uuid()} className="ps-1 text-danger">
                      {message}
                    </p>
                  ))}
                  {errors.id_matricola && <p className="ps-2 text-danger">Formato non valido.</p>}
                </div>
                <div className="form-floating mb-3">
                  <input
                    name="password"
                    type="password"
                    {...register("password", {
                      required: true,
                      pattern: new RegExp(process.env.REACT_APP_REGEX_PASSWORD),
                    })}
                    className={`form-control ${!errors?.password ? " " : " is-invalid "}`}
                    id="password"
                    placeholder="Password"
                    required
                  />
                  <label htmlFor="floatingPassword">Password</label>
                  {messageError?.password?.map(({ message }) => {
                    return (
                      <>
                        <p key={uuid()} className="ps-1 text-danger">
                          {message}
                        </p>
                      </>
                    );
                  })}
                  {errors?.password && (
                    <>
                      <p className="ps-2 text-danger">
                      La nuova password deve soddisfare i seguenti requisiti:
                      </p>
                      <ul className="text-danger ps-4">
                        <li>Contenere almeno una lettera minuscola.</li>
                        <li>Contenere almeno una lettera maiuscola.</li>
                        <li>Includere almeno un carattere speciale tra i seguenti: <code>!@#$%-</code></li>
                        <li>Avere una lunghezza compresa tra 8 e 24 caratteri.</li>
                      </ul>
                    </>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    name="confirmedPassword"
                    type="password"
                    {...register("confirmedPassword", {
                      validate: (value) => {
                        const { password } = getValues();
                        return password === value;
                      },
                    })}
                    className={`form-control ${!errors?.confirmedPassword ? "  " : " is-invalid "}`}
                    id="confirmedPassword"
                    placeholder="Conferma Password"
                    required
                  />
                  <label htmlFor="floatingPassword">Conferma Password</label>
                  {messageError?.confirmedPassword?.map(({ message }) => (
                    <p key={uuid()} className="ps-1 text-danger">
                      {message}
                    </p>
                  ))}
                  {errors?.confirmedPassword && <p className="ps-2 text-danger">le password non coincidono</p>}
                </div>
                <div className="d-grid">
                  <button
                    type="submit"
                    form="loginForm"
                    id="btn-login"
                    className={`btn btn-primary btn-login text-uppercase mb-2`}
                    disabled={!isDirty || !isValid}>
                    Registrati
                  </button>
                </div>
                <div className="pt-4">
                  Hai già un'account?{" "}
                  <a href="/login" className="non-account text-decoration-none">
                    Accedi
                  </a>
                </div>
              </form>
            </>
          ) : (
            <>
              <ResendEmail email={email} {...props} />
            </>
          )}
        </>
      )}
    </>
  );
}
export default RegistrationFormWithLibrary;